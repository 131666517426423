// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-advisor-post-js": () => import("/opt/build/repo/src/templates/advisor-post.js" /* webpackChunkName: "component---src-templates-advisor-post-js" */),
  "component---src-templates-who-we-serve-js": () => import("/opt/build/repo/src/templates/who-we-serve.js" /* webpackChunkName: "component---src-templates-who-we-serve-js" */),
  "component---src-templates-blog-post-js": () => import("/opt/build/repo/src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-news-post-js": () => import("/opt/build/repo/src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-member-post-js": () => import("/opt/build/repo/src/templates/member-post.js" /* webpackChunkName: "component---src-templates-member-post-js" */),
  "component---src-templates-partner-post-js": () => import("/opt/build/repo/src/templates/partner-post.js" /* webpackChunkName: "component---src-templates-partner-post-js" */),
  "component---src-templates-page-js": () => import("/opt/build/repo/src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/opt/build/repo/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("/opt/build/repo/src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("/opt/build/repo/src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-videos-js": () => import("/opt/build/repo/src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */)
}

